import React, { useState } from 'react'
import {
  Pane,
  Text,
  Badge,
  Dialog,
  Icon,
  Button,
  RouteIcon,
  CircleArrowRightIcon,
  UserIcon,
  ShareIcon,
  TimeIcon,
  CubeIcon,
  CloudDownloadIcon,
  PropertyIcon,
  minorScale,
  Popover,
  Position,
  TextInputField,
  Alert,
} from 'evergreen-ui'
import dayjs from 'dayjs'
import prettyBytes from 'pretty-bytes'
import { JobsAPI } from '../../api'

export default function Card(
  props = {
    opt: {
      id: '',
      project_name: '',
      stage: '',
      status: '',
      graphhopper_status: '',
      export_format: '',
      delivery_target: '',
      delivery_file_path: '',
      delivery_log_path: '',
      user: {},
      created_at: '',
    },
    refreshList: () => {},
  },
) {
  const { opt, refreshList } = props

  const [ghDialogVisible, setGhDialogVisible] = useState(false)
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)
  const [cancelDialogError, setCancelDialogError] = useState(null)
  const [jobSize, setJobSize] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <Badge color='neutral'>{status}</Badge>
      case 'processing':
        return <Badge color='blue'>{status}</Badge>
      case 'canceled':
        return <Badge color='neutral'>{status}</Badge>
      case 'done':
        return <Badge color='green'>{status}</Badge>
      case 'failed':
        return <Badge color='red'>{status}</Badge>
      default:
        return <Badge color='red'>? {status}</Badge>
    }
  }

  const isAutoBackupJob = () => {
    return opt.user.email === 'service_user@nb.ai'
  }

  const getAutoBackupBadge = () => {
    if (isAutoBackupJob()) {
      return <Badge color='teal'>Auto Backup</Badge>
    }
    return null
  }

  const getCardBackgroundColor = () => {
    if (isAutoBackupJob()) {
      return '#f2f7f4'
    }
    return '#fff'
  }

  const handleDownloadClick = async () => {
    try {
      setLoading(true)
      const res = await JobsAPI.getDownloadLink(opt.id)
      if (res.link) {
        window.location.assign(res.link)
      }
    } catch (err) {
      console.warn(err)
    } finally {
      setLoading(false)
    }
  }

  const handleGetSize = async () => {
    try {
      setLoading(true)
      const res = await JobsAPI.getSize(opt.id)
      if (res.size_in_bytes) {
        setJobSize(res.size_in_bytes)
      }
    } catch (err) {
      console.warn(err)
    } finally {
      setLoading(false)
    }
  }

  const handleCancelConfirm = async () => {
    try {
      setCancelDialogError('')
      setLoading(true)
      await JobsAPI.cancelByID(opt.id)
      setCancelDialogVisible(false)
    } catch (err) {
      console.warn(err)
      setCancelDialogError(err.data || 'Unknown error')
    } finally {
      setLoading(false)
    }
  }

  const handleViewLog = async () => {
    try {
      setLoading(true)
      const res = await JobsAPI.getLogLink(opt.id)
      if (res.link) {
        window.open(res.link, '_blank')
      }
    } catch (err) {
      console.warn(err)
    } finally {
      setLoading(false)
    }
  }

  const handleGraphhopper = async () => {
    try {
      setLoading(true)
      await JobsAPI.enableGraphhopper(opt.id)
      if (typeof refreshList === 'function') {
        refreshList()
      }
      setGhDialogVisible(false)
    } catch (err) {
      console.warn(err)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenInGraphhopper = () => {
    window.open(`https://gh-${window.location.hostname}`, '_blank')
  }

  const addCloudStoragePrefix = (path) => {
    switch (opt.delivery_target) {
      case 'gcs':
        return `gs://${path}`
      case 'aws_s3':
        return `s3://${path}`
      default:
        return path
    }
  }

  return (
    <Pane
      width='100%'
      padding={18}
      backgroundColor={getCardBackgroundColor()}
      elevation={1}
      marginTop={24}
      display='flex'
      flexDirection='column'
    >
      <Pane width='100%' display='flex' alignItems='center' justifyContent='space-between'>
        <Pane display='flex' alignItems='center'>
          <Pane>
            <Icon color='muted' icon={CubeIcon} marginRight={4} />
            <Text size={600}>{opt.project_name}</Text>
          </Pane>

          <Pane marginLeft={36}>
            <Text size={600} fontWeight={700}>
              {opt.export_format.toUpperCase()}
            </Text>
            <Icon color='muted' icon={CircleArrowRightIcon} paddingX={8} />
            <Text size={600} fontWeight={700}>
              {opt.delivery_target.toUpperCase()}
            </Text>
          </Pane>

          {opt.status !== 'done' && (
            <Pane marginLeft={24}>
              <Text>Current Stage: {opt.stage.charAt(0).toUpperCase() + opt.stage.slice(1)}</Text>
            </Pane>
          )}
        </Pane>

        <Pane display='flex'>
          <Pane paddingRight={6}>{getAutoBackupBadge()}</Pane>
          <Pane>{getStatusBadge(opt.status)}</Pane>
        </Pane>
      </Pane>

      <Pane width='100%' display='flex' alignItems='center' justifyContent='space-between' marginTop={24}>
        <Pane display='flex' alignItems='center'>
          <Icon icon={TimeIcon} color='muted' />
          <Text color='muted' size={400} marginLeft={4}>
            {dayjs.unix(opt.created_at).format('YYYY-MM-DD HH:mm:ss (ZZ)')}
          </Text>

          <Icon icon={UserIcon} color='muted' marginLeft={24} />
          <Text color='muted' size={400} marginLeft={4}>
            Started by {opt.user.email}
          </Text>
        </Pane>

        <Pane>
          <Dialog
            isShown={ghDialogVisible}
            onCloseComplete={() => setGhDialogVisible(false)}
            onConfirm={handleGraphhopper}
            title='Graphhopper Map Update'
            preventBodyScrolling
            confirmLabel='Continue'
            isConfirmLoading={loading}
            intent='danger'
          >
            <Pane>
              <Pane marginBottom={minorScale(4)}>
                <Alert
                  intent='warning'
                  title='Triggering Graphhopper map update will affect any user who is currently using the Graphhopper instance.
'
                />
              </Pane>
              <Pane marginBottom={minorScale(4)}>
                <Text>
                  Once triggered, the Graphhopper instance will be restarted and imports the selected PBF. It usually
                  takes a few minutes to finish, with larger map area to take longer. Before restart finishes,
                  Graphhopper is expected to show an error page or the previous map data.
                </Text>
              </Pane>
              <Pane marginBottom={minorScale(4)}>
                <Text>Do you want to continue and trigger map update?</Text>
              </Pane>
            </Pane>
          </Dialog>

          <Dialog
            isShown={cancelDialogVisible}
            onCloseComplete={() => setCancelDialogVisible(false)}
            onConfirm={handleCancelConfirm}
            title='Cancel Job'
            preventBodyScrolling
            confirmLabel='Confirm'
            isConfirmLoading={loading}
            intent='danger'
          >
            <Pane>
              {cancelDialogError ? (
                <Pane marginBottom={minorScale(4)}>
                  <Alert intent='danger' title={cancelDialogError} />
                </Pane>
              ) : null}
              <Pane marginBottom={minorScale(4)}>
                <Text>Would you like to cancel this job?</Text>
              </Pane>
            </Pane>
          </Dialog>

          {opt.export_format === 'pbf' && opt.graphhopper_status === 'disabled' ? (
            <Button
              iconBefore={RouteIcon}
              disabled={loading || opt.status !== 'done'}
              onClick={() => setGhDialogVisible(true)}
              marginRight={minorScale(2)}
            >
              Graphhopper
            </Button>
          ) : null}

          {opt.export_format === 'pbf' && opt.graphhopper_status !== 'disabled' ? (
            <Button
              iconBefore={RouteIcon}
              disabled={loading}
              onClick={handleOpenInGraphhopper}
              marginRight={minorScale(2)}
            >
              Open in Graphhopper
            </Button>
          ) : null}

          <Popover
            position={Position.TOP_RIGHT}
            content={
              <Pane width={360} padding={minorScale(4)} display='flex' justifyContent='center' flexDirection='column'>
                <Pane display='flex'>
                  <TextInputField
                    label='Job ID'
                    readOnly={true}
                    value={opt.id}
                    marginLeft={0}
                    marginRight={0}
                    flex={1}
                  />
                </Pane>
                <Pane display='flex'>
                  <TextInputField
                    label={`${opt.export_format.toUpperCase()} Path`}
                    readOnly={true}
                    value={addCloudStoragePrefix(opt.delivery_file_path)}
                    marginTop={0}
                    marginLeft={0}
                    marginRight={0}
                    flex={1}
                  />
                  {jobSize ? (
                    <Pane marginLeft={minorScale(2)} marginBottom={minorScale(6)} alignSelf='flex-end'>
                      <Text size={300}>{prettyBytes(jobSize)}</Text>
                    </Pane>
                  ) : (
                    <Button
                      disabled={loading || opt.status !== 'done'}
                      onClick={handleGetSize}
                      marginLeft={minorScale(2)}
                      marginBottom={minorScale(6)}
                      alignSelf='flex-end'
                    >
                      Get Size
                    </Button>
                  )}
                </Pane>
                <Pane display='flex'>
                  <TextInputField
                    label='Log File Path'
                    readOnly={true}
                    value={addCloudStoragePrefix(opt.delivery_log_path)}
                    marginBottom={0}
                    marginLeft={0}
                    marginRight={0}
                    flex={1}
                  />
                  <Button
                    iconBefore={ShareIcon}
                    disabled={loading || opt.status !== 'done'}
                    onClick={handleViewLog}
                    marginLeft={minorScale(2)}
                    alignSelf='flex-end'
                  >
                    View
                  </Button>
                </Pane>
              </Pane>
            }
          >
            <Button iconBefore={PropertyIcon}>Properties</Button>
          </Popover>
          {opt.status === 'processing' ? (
            <Button
              intent='danger'
              appearance='primary'
              disabled={loading}
              onClick={() => setCancelDialogVisible(true)}
              marginLeft={minorScale(2)}
            >
              Cancel
            </Button>
          ) : (
            <Button
              intent='success'
              iconBefore={CloudDownloadIcon}
              disabled={loading || opt.status !== 'done'}
              onClick={handleDownloadClick}
              marginLeft={minorScale(2)}
            >
              Download
            </Button>
          )}
        </Pane>
      </Pane>
    </Pane>
  )
}
