import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from './../../auth'
import { Pane, Alert, Button, Heading, majorScale } from 'evergreen-ui'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export default function Auth() {
  const [warning, setWarning] = useState('')
  const [info, setInfo] = useState('')
  const [disabled, setDisabled] = useState(false)

  const query = useQuery()
  const history = useHistory()
  const auth = useAuth()

  const handleSSOInit = async () => {
    try {
      setWarning('')
      const url = await auth.requestSSOAuthPage()
      window.location.replace(url)
    } catch (err) {
      console.warn(err)
      setWarning('Error')
    }
  }

  const state = query.get('state')
  const sessionState = query.get('session_state')
  const code = query.get('code')

  useEffect(() => {
    if (auth.isAuthenticated() === true) {
      history.replace('/')
      return
    }

    const handleSSOCallback = async (state, sessionState, code) => {
      try {
        setInfo('Logging in...')
        setDisabled(true)
        await auth.handleSSOCallback(state, sessionState, code)
      } catch (err) {
        setWarning('Failed to obtain a token, please re-try')
        console.warn(err)
        setInfo('')
        setDisabled(false)
      }
    }

    if (state && sessionState && code) {
      handleSSOCallback(state, sessionState, code)
    }
  }, [state, sessionState, code, auth, history])

  return (
    <Pane display='flex' alignItems='center' flexDirection='column'>
      <Heading size={900} marginTop={majorScale(3)}>
        Bellhop
      </Heading>
      <Pane
        display='flex'
        alignItems='center'
        flexDirection='column'
        marginTop={64}
        backgroundColor='#fff'
        borderRadius={7}
      >
        {warning && (
          <Pane marginTop={16}>
            <Alert intent='danger' title={warning} />
          </Pane>
        )}
        {info && (
          <Pane marginTop={16}>
            <Alert intent='none' title={info} />
          </Pane>
        )}
        <Pane paddingY={64} paddingX={128}>
          <Button onClick={handleSSOInit} appearance='primary' height={64} disabled={disabled}>
            NB Pass Login
          </Button>
        </Pane>
      </Pane>
    </Pane>
  )
}
