import React, { useEffect, useState, useCallback } from 'react'
import {
  Pane,
  Menu,
  Position,
  Popover,
  Heading,
  Alert,
  Text,
  FilePicker,
  Strong,
  Button,
  Icon,
  GlobeIcon,
  LogOutIcon,
  UserIcon,
  Spinner,
  StatusIndicator,
  SelectMenu,
  CubeIcon,
} from 'evergreen-ui'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from './../../auth'
import { OSMAPI, ProjectsAPI } from './../../api'
import ImgLogo from './../../assets/images/bellhop.png'
import './styles.css'

export default function Jobs() {
  const [projectList, setProjectList] = useState([])
  const [project, setProject] = useState('')
  const [file, setFile] = useState(null)

  const [moduleAccess, setModuleAccess] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const auth = useAuth()

  const fetchProjectList = useCallback(async () => {
    try {
      setLoading(true)
      const res = await ProjectsAPI.getAll()
      if (Array.isArray(res)) {
        setProjectList(res)
      } else {
        throw new Error('malformed project list data')
      }
    } catch (err) {
      console.warn(err)
      throw new Error(err.data || err)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpload = async () => {
    try {
      setLoading(true)
      setError('')
      setSuccess('')

      await OSMAPI.upsertUserByCsv(project, file)
      setSuccess(
        'Upsert users completed. Please verify access on the selected project instance. In case of any issue please contact the Tech team.',
      )
    } catch (err) {
      setError(err.data ? err.data : 'Upload failed for unknown reason. Please retry later or contact the Tech team.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // validate if current user has the "osm-admin" role
    if (Array.isArray(auth.user.roles) && auth.user.roles.indexOf('osm-admin') > -1) {
      setModuleAccess(true)
      fetchProjectList()
    }
  }, [fetchProjectList, auth])

  return (
    <Pane background='tint1' minHeight='100vh'>
      <Pane padding={6} display='flex' justifyContent='space-between' flexDirection='row'>
        <Pane display='flex' alignItems='center' className='cursorDefault'>
          <Pane>
            <img height={36} src={ImgLogo} alt='Bellhop Logo' />
          </Pane>
          <Pane paddingLeft={8}>
            <Heading size={800}>Bellhop</Heading>
          </Pane>
        </Pane>

        <Pane display='flex' alignItems='center'>
          <Pane>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Item icon={LogOutIcon} onClick={auth.handleSignOut}>
                    Sign Out
                  </Menu.Item>
                </Menu>
              }
            >
              <Button size='large'>{auth.user.email}</Button>
            </Popover>
          </Pane>
        </Pane>
      </Pane>

      <Pane padding={6} display='flex' justifyContent='center' alignItems='center' flexDirection='row'>
        <Pane padding={12}>
          <RouterLink to='/' style={{ textDecoration: 'none', color: '#696f8c', fontWeight: '700' }}>
            <Pane padding={12} display='flex' alignItems='center' justifyContent='center'>
              <Icon icon={GlobeIcon} color='muted' size={20} paddingRight={4} />
              <Pane>Data Export</Pane>
            </Pane>
          </RouterLink>
        </Pane>

        <Pane padding={12}>
          <RouterLink to='/osm-user' style={{ textDecoration: 'none', color: '#52BD95', fontWeight: '700' }}>
            <Pane padding={12} display='flex' alignItems='center' justifyContent='center'>
              <Icon icon={UserIcon} color='green500' size={20} paddingRight={4} />
              <Pane>OSM User</Pane>
            </Pane>
          </RouterLink>
        </Pane>
      </Pane>

      <Pane maxWidth={1000} marginLeft='auto' marginRight='auto'>
        <Pane>
          <Alert intent='none' title='How to Use'>
            <Pane display='flex' flexDirection='column'>
              <StatusIndicator>
                <Text>This module allows an OSM Administrator to batch update the user access to any XSM project.</Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>
                  One needs to upload a CSV file containing the necessary columns: <strong>email</strong>,{' '}
                  <strong>display_name</strong>, <strong>password</strong>. Other fields will be silently ignored.
                </Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>
                  <strong>Email</strong>s and <strong>display_name</strong>s must be unique on the same project.
                </Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>
                  Given the same <strong>display_name</strong>s on a project, the last will overwrite the former
                  records.
                </Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>
                  This module will always append new users and will not delete any user even by uploading an exclusive
                  CSV file.
                </Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>The size of the CSV file should be under 10MB.</Text>
              </StatusIndicator>
              <StatusIndicator>
                <Text>
                  Changes shall take into effect immediately so please <u>DO BE CAREFUL</u>.
                </Text>
              </StatusIndicator>
            </Pane>
          </Alert>
        </Pane>

        <Pane display='flex' marginTop={12} justifyContent='center' alignItems='center'>
          <Pane>
            <Spinner opacity={loading ? 1 : 0} className='spinner' />
          </Pane>
        </Pane>

        {error && (
          <Pane marginTop={16}>
            <Alert intent='danger' title={error} />
          </Pane>
        )}

        {success && (
          <Pane marginTop={16}>
            <Alert intent='success' title={success} />
          </Pane>
        )}

        {moduleAccess ? (
          <Pane>
            <Pane display='flex' alignItems='center' marginTop={24}>
              <Strong width={120}>Select a Project</Strong>
              <SelectMenu
                title='Choose a project'
                options={projectList.map((label) => ({ label, value: label }))}
                selected={project}
                onSelect={(item) => setProject(item.value)}
                filterPlaceholder={'Search by name'}
                filterIcon={CubeIcon}
                closeOnSelect
                disabled={loading}
              >
                <Button minWidth={170} marginLeft={12} textAlign='center'>
                  {project || 'Choose a project'}
                </Button>
              </SelectMenu>
            </Pane>

            <Pane display='flex' alignItems='center' marginTop={24}>
              <Strong width={120}>CSV File</Strong>
              <FilePicker
                disabled={!Boolean(project) || loading}
                onChange={(files) => setFile(files[0])}
                width={170}
                marginLeft={12}
              />
            </Pane>

            <Pane display='flex' alignItems='center' marginTop={24}>
              <Button height={56} intent='success' appearance='primary' onClick={handleUpload} disabled={loading}>
                Upsert Users
              </Button>
            </Pane>
          </Pane>
        ) : (
          <Pane>
            <Alert intent='warning' title='Not Authorized'>
              <Text>
                Please contact{' '}
                <a
                  href={`mailto:yun@nextbillion.ai?subject=Request Bellhop  Access&body=Hi, please help grant Bellhop OSM Administrator Access to ${auth.user.email}. Thank you!`}
                >
                  Yun Wang
                </a>{' '}
                to get access to this module. After that please sign out and sign in again to refresh access. Thank you!
              </Text>
            </Alert>
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}
