import axios from 'axios'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || '/api',
  timeout: 10 * 1000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
})

API.interceptors.response.use(
  (resp) => resp.data,
  (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem('bellhop_user_data')
      localStorage.removeItem('bellhop_session_token')
      window.location.replace('/auth')
    } else {
      return Promise.reject(err.response || `The server failed to response your request`)
    }
  },
)

export default API

export { AuthAPI } from './endpoints/auth'
export { JobsAPI } from './endpoints/jobs'
export { ProjectsAPI } from './endpoints/projects'
export { OSMAPI } from './endpoints/osm'
