import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ProvideAuth, useAuth } from './auth'
import AuthPage from './pages/auth'
import JobsPage from './pages/jobs'
import OsmUser from './pages/osm-user'

const Routes = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path='/auth'>
            <AuthPage />
          </Route>
          <PrivateRoute path='/osm-user'>
            <OsmUser />
          </PrivateRoute>
          <PrivateRoute path='/'>
            <JobsPage />
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user && auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: location } }} />
        )
      }
    />
  )
}


export default Routes
