import API from '..'

export const OSMAPI = {
  upsertUserByCsv: (projectName, file) => {
    const fd = new FormData()
    fd.append('file', file)
    fd.append('project_name', projectName)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return API.post(`/osm.upsertUserByCsv`, fd, config)
  },
}
