import API from '..'

export const AuthAPI = {
  auth: () => {
    return API.post('/auth')
  },
  callback: (state, sessionState, code) => {
    return API.post(`/auth.callback?state=${state}&session_state=${sessionState}&code=${code}`)
  },
}
