import React, { useEffect, useState, useCallback } from 'react'
import {
  Pane,
  Menu,
  Position,
  Popover,
  Heading,
  Alert,
  Text,
  Strong,
  Button,
  Icon,
  GlobeIcon,
  LogOutIcon,
  UserIcon,
  Spinner,
  Dialog,
  SegmentedControl,
  SelectMenu,
  CubeIcon,
} from 'evergreen-ui'
import { Link as RouterLink } from 'react-router-dom'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import { useAuth } from './../../auth'
import { JobsAPI, ProjectsAPI } from './../../api'
import ImgLogo from './../../assets/images/bellhop.png'
import Card from './card'
import './styles.css'
import moment from 'moment'

const formatOptions = [
  { label: 'PBF', value: 'pbf' },
  { label: 'Database Dump', value: 'sql' },
]

const methodOptions = [
  { label: 'osmosis', value: 'osmosis' },
  { label: 'rosmosis (experimental)', value: 'rosmosis' },
]

const targetOptions = [
  { label: 'Google Cloud Storage', value: 'gcs' },
  { label: 'AWS S3', value: 'aws_s3' },
]

let pullTimer = null

export default function Jobs() {
  const [jobs, setJobs] = useState([])
  const [showNJDialog, setShowNJDialog] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [project, setProject] = useState('')
  const [format, setFormat] = useState('pbf')
  const [method, setMethod] = useState('osmosis')
  const [target, setTarget] = useState('gcs')
  const [datetime, setDatetime] = useState(moment())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const auth = useAuth()

  const handleNewJobClick = async () => {
    try {
      setProject('')
      setFormat('pbf')
      setMethod('osmosis')
      setTarget('gcs')
      await fetchProjectList()
      setShowNJDialog(true)
    } catch (err) {
      console.warn(err)
      setError('Failed to start a new pipeline. Tech detail: ' + err)
    }
  }

  const fetchProjectList = async () => {
    try {
      setLoading(true)
      const res = await ProjectsAPI.getAll()
      if (Array.isArray(res)) {
        setProjectList(res)
      } else {
        throw new Error('malformed project list data')
      }
    } catch (err) {
      console.warn(err)
      throw new Error(err.data || err)
    } finally {
      setLoading(false)
      setShowNJDialog(false)
    }
  }

  const handleConfirmNewJobClick = async () => {
    try {
      setLoading(true)
      await JobsAPI.create(project, format, method, target)
      handleSearchByDate()
    } catch (err) {
      console.warn(err)
      setError('Failed to start a pipeline. Tech detail: ' + err.data || err)
    } finally {
      setLoading(false)
      setShowNJDialog(false)
    }
  }

  const isValidDate = (current) => {
    return current.isAfter(moment('2021-06-07')) && current.isBefore(moment())
  }

  const handleDatePickerChange = (mmt) => {
    setDatetime(mmt)
  }

  const handleSearchByDate = useCallback(async () => {
    if (!datetime) {
      return
    }

    try {
      setLoading(true)
      setError('')
      const res = await JobsAPI.searchByDate(moment(datetime))
      if (Array.isArray(res)) {
        setJobs(res)
        // when there are jobs neither failed or done
        // make an automated list pull after a period
        if (res.some((o) => ['done', 'failed'].indexOf(o.status) < 0)) {
          clearTimeout(pullTimer)
          pullTimer = setTimeout(handleSearchByDate, 2000)
        }
      }
    } catch (err) {
      console.warn(err)
      setError(`Failed to search by date. Server response: ${err.data || 'unknown'}`)
    } finally {
      setLoading(false)
    }
  }, [datetime])

  useEffect(() => {
    handleSearchByDate()
    return () => {
      // prevent future automated list pull when leaving page
      clearTimeout(pullTimer)
    }
  }, [handleSearchByDate])

  return (
    <Pane background='tint1' minHeight='100vh'>
      <Pane padding={6} display='flex' justifyContent='space-between' flexDirection='row'>
        <Pane display='flex' alignItems='center' className='cursorDefault'>
          <Pane>
            <img height={36} src={ImgLogo} alt='Bellhop Logo' />
          </Pane>
          <Pane paddingLeft={8}>
            <Heading size={800}>Bellhop</Heading>
          </Pane>
        </Pane>

        <Pane display='flex' alignItems='center'>
          <Pane>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Item icon={LogOutIcon} onClick={auth.handleSignOut}>
                    Sign Out
                  </Menu.Item>
                </Menu>
              }
            >
              <Button size='large'>{auth.user.email}</Button>
            </Popover>
          </Pane>
        </Pane>
      </Pane>

      <Pane padding={6} display='flex' justifyContent='center' alignItems='center' flexDirection='row'>
        <Pane padding={12}>
          <RouterLink to='/' style={{ textDecoration: 'none', color: '#52BD95', fontWeight: '700' }}>
            <Pane padding={12} display='flex' alignItems='center' justifyContent='center'>
              <Icon icon={GlobeIcon} color='green500' size={20} paddingRight={4} />
              <Pane>Data Export</Pane>
            </Pane>
          </RouterLink>
        </Pane>

        <Pane padding={12}>
          <RouterLink to='/osm-user' style={{ textDecoration: 'none', color: '#696f8c', fontWeight: '700' }}>
            <Pane padding={12} display='flex' alignItems='center' justifyContent='center'>
              <Icon icon={UserIcon} color='muted' size={20} paddingRight={4} />
              <Pane>OSM User</Pane>
            </Pane>
          </RouterLink>
        </Pane>
      </Pane>

      <Pane maxWidth={1000} marginLeft='auto' marginRight='auto'>
        <Pane height={108} display='flex' justifyContent='space-between' alignItems='center'>
          <Pane display='flex' alignItems='center'>
            <Pane>
              <Text color='muted' size={400} marginLeft={4}>
                Search By Date
              </Text>
              <Datetime
                className={'datetimeInput'}
                dateFormat={'YYYY-MM-DD'}
                timeFormat={false}
                isValidDate={isValidDate}
                initialValue={datetime}
                onChange={handleDatePickerChange}
                inputProps={{ readOnly: 'readonly' }}
                closeOnSelect
              />
            </Pane>
          </Pane>
          <Pane>
            <Spinner opacity={loading ? 1 : 0} className='spinner' />
          </Pane>
          <Button height={56} intent='success' appearance='primary' onClick={handleNewJobClick}>
            Start A New Job
          </Button>
        </Pane>

        {error && (
          <Pane marginTop={16}>
            <Alert intent='danger' title={error} />
          </Pane>
        )}

        <Pane>
          {!loading && jobs.length === 0 ? (
            <Pane
              backgroundColor='#fff'
              width='100%'
              paddingY={32}
              elevation={2}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Icon icon={GlobeIcon} color='muted' size={32} />
              <Text size={600} color='muted' marginLeft={16}>
                Nothing Yet
              </Text>
            </Pane>
          ) : null}

          {jobs.length > 0 ? (
            <Pane wordBreak='break-word' paddingBottom={64}>
              {jobs.map((job) => (
                <Pane key={job.id}>
                  <Card opt={job} refreshList={handleSearchByDate} />
                </Pane>
              ))}
            </Pane>
          ) : null}
        </Pane>
      </Pane>

      <Dialog
        isShown={showNJDialog}
        title='Start A New Job'
        onCloseComplete={() => {
          setShowNJDialog(false)
        }}
        intent='success'
        confirmLabel={loading ? 'Starting...' : 'Start Job'}
        isConfirmLoading={loading}
        isConfirmDisabled={loading || !project}
        onConfirm={handleConfirmNewJobClick}
        preventBodyScrolling
      >
        <Pane display='flex' alignItems='center'>
          <Pane>
            <Pane display='flex' alignItems='center'>
              <Strong width={120}>Project</Strong>
              <SelectMenu
                title='Choose a project'
                options={projectList.map((label) => ({ label, value: label }))}
                selected={project}
                onSelect={(item) => setProject(item.value)}
                filterPlaceholder={'Search by name'}
                filterIcon={CubeIcon}
                closeOnSelect
              >
                <Button minWidth={170} marginLeft={12} textAlign='center'>
                  {project || 'Choose a project'}
                </Button>
              </SelectMenu>
            </Pane>

            <Pane display='flex' alignItems='center' marginTop={12}>
              <Strong width={120}>Map Data Format</Strong>
              <SegmentedControl
                marginLeft={12}
                width={340}
                options={formatOptions}
                value={format}
                onChange={(val) => setFormat(val)}
              />
            </Pane>

            <Pane display='flex' alignItems='center' marginTop={12}>
              <Strong width={120}>Exporter Method</Strong>
              <SegmentedControl
                marginLeft={12}
                width={340}
                options={methodOptions}
                value={method}
                onChange={(val) => setMethod(val)}
              />
            </Pane>

            <Pane display='flex' alignItems='center' marginTop={12}>
              <Strong width={120}>Delivery Target</Strong>
              <SegmentedControl
                marginLeft={12}
                width={340}
                options={targetOptions}
                value={target}
                onChange={(val) => setTarget(val)}
              />
            </Pane>
          </Pane>
        </Pane>
      </Dialog>
    </Pane>
  )
}
