import API from '..'

export const JobsAPI = {
  create: (projectName, format, method, target) => {
    return API.post(`/job.create?project_name=${projectName}&format=${format}&method=${method}&target=${target}`)
  },
  getByID: (id) => {
    return API.post(`/job.get?id=${id}`)
  },
  getAll: (page) => {
    return API.post(`/jobs.get?page=${page}`)
  },
  getDownloadLink: (id) => {
    return API.post(`/job.getLink?id=${id}`)
  },
  getSize: (id) => {
    return API.post(`/job.getSize?id=${id}`)
  },
  cancelByID: (id) => {
    return API.post(`/job.cancel?id=${id}`)
  },
  getLogLink: (id) => {
    return API.post(`/log.getLink?id=${id}`)
  },
  searchByDate: (date) => {
    return API.post(
      `/jobs.searchByDate?start_time=${date.utc().startOf('day').format()}&end_time=${date
        .add(1, 'day')
        .utc()
        .startOf('day')
        .format()}`,
    )
  },
  enableGraphhopper: (id) => {
    return API.post(`/job.enableGraphhopper?id=${id}`)
  },
}
