import { useState, createContext, useContext } from 'react'
import API, { AuthAPI } from './api'

let token = null
let userData = null

token = localStorage.getItem('bellhop_session_token') || null
const storedUser = localStorage.getItem('bellhop_user_data') || null

try {
  if (typeof storedUser === 'string' && storedUser.length) {
    userData = JSON.parse(storedUser)
  } else {
    throw new Error('invalid cached user data')
  }

  if (typeof token === 'string' && token.length) {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    throw new Error('invalid cached token')
  }
} catch (err) {
  localStorage.removeItem('bellhop_user_data')
  localStorage.removeItem('bellhop_session_token')
}

const authHub = {
  isAuthenticated: !!token && !!storedUser,
  async signin(token, cb) {
    try {
      authHub.isAuthenticated = true
      // set Parse server session token
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('bellhop_session_token', token)

      const userData = await AuthAPI.auth()
      localStorage.setItem('bellhop_user_data', JSON.stringify(userData))

      if (typeof cb === 'function') {
        cb(userData)
      }
    } catch (err) {
      throw err
    }
  },
  signout() {
    authHub.isAuthenticated = false
    localStorage.removeItem('bellhop_user_data')
    localStorage.removeItem('bellhop_session_token')
    API.defaults.headers.common['Authorization'] = ''
    return
  },
}

const authContext = createContext()

export function useAuth() {
  return useContext(authContext)
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useProvideAuth() {
  const [user, setUser] = useState(userData || null)

  const isAuthenticated = () => {
    return authHub.isAuthenticated
  }

  const requestSSOAuthPage = async () => {
    try {
      const url = await AuthAPI.auth()
      new URL(url)
      return url
    } catch (err) {
      throw err
    }
  }

  const handleSSOCallback = async (state, sessionState, code) => {
    try {
      const res = await AuthAPI.callback(state, sessionState, code)
      return authHub.signin(res.token, (user) => {
        setUser(user)
      })
    } catch (err) {
      throw err
    }
  }

  const handleSignOut = () => {
    authHub.signout()
    window.location.replace('/auth')
  }

  return {
    user,
    requestSSOAuthPage,
    handleSSOCallback,
    handleSignOut,
    isAuthenticated,
  }
}
